<template>
  <section id="all-emr-orders-view" class="all-cases-view">
    <div class="navbarComponent">
      <div class="container-default">
        <div class="listings-navbar">
          <el-row>
            <el-col :lg="7">
              <ul class="d-flex list-items-group-15 barcode-print">
                <li>
                  <div class="form-element input-with-icon search-input-lg">
                    <el-input
                      placeholder="Accession / Patient Name"
                      v-model="search_string"
                      @input="getAutoCompleteOrders()"
                      :clearable="true"
                      suffix-icon="el-icon-date"
                    ></el-input>
                    <span>
                      <img
                        src="@/assets/images/icons/search-icon-with-bg-red.svg"
                        alt="icon"
                        class="img-fluid icon"
                      />
                    </span>
                  </div>
                </li>
              </ul>
            </el-col>
            <el-col :span="17">
              <ul
                class="d-flex flex-wrap justify-content-end list-items-group-15"
              >
                <li>
                  <div
                    class="form-element search-with-filter-input-without-icon"
                  >
                    <el-select
                      v-model="searchBy"
                      clearable
                      filterable
                      default-first-option
                      :reserve-keyword="false"
                      placeholder="Select Search"
                      @change="clearInputSearch"
                      @clear="clearSearchBy"
                      @focus="initializeSearchOptions"
                    >
                      <el-option
                        v-for="item in searchOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                  </div>
                </li>

                <li v-if="searchBy == 'Patient DOB'">
                  <div class="search-with-date-input">
                    <el-date-picker
                      format="MM-DD-YYYY"
                      v-model="date_of_birth"
                      :disabled-date="disabledDate"
                      @change="fetchEmrOrders()"
                      placeholder="Select DOB"
                      :clearable="true"
                    ></el-date-picker>
                  </div>
                </li>
                <li v-else-if="searchBy == 'hospital'">
                  <div class="form-element search-with-filter-input">
                    <el-select
                      v-model="hospital"
                      placeholder="Select Ordering Facility"
                      filterable
                      clearable
                      default-first-option
                      @change="handleCurrentChange(1)"
                      @focus="fetchAllHospitals"
                      :loading="hospitalsLoading"
                    >
                      <el-option
                        v-for="hospital in hospitalsData.data"
                        :key="hospital"
                        :label="hospital.name"
                        :value="hospital._id"
                      >
                      </el-option>
                    </el-select>
                    <span class="search-icon">
                      <img
                        src="@/assets/images/icons/search-icon-with-bg-red.svg"
                        alt="icon"
                        class="img-fluid"
                      />
                    </span>
                  </div>
                </li>
                <li v-else-if="searchBy == 'emr_client'">
                  <div class="form-element search-with-filter-input">
                    <el-select
                      v-model="emr_client"
                      placeholder="Select EMR"
                      @change="fetchEmrOrders(1)"
                      clearable
                      default-first-option
                      :filter-method="filterEmrClient"
                      @focus="fetchAllEMRClients()"
                      :loading="emrClientsLoading"
                    >
                      <el-option
                        v-for="item in emrClients"
                        :key="item._id"
                        :value="item._id"
                        :label="item.name"
                      ></el-option>
                    </el-select>
                    <span class="search-icon">
                      <img
                        src="@/assets/images/icons/search-icon-with-bg-red.svg"
                        alt="icon"
                        class="img-fluid"
                      />
                    </span>
                  </div>
                </li>
                <li v-else-if="searchBy == 'ordering_physician'">
                  <div class="form-element search-with-filter-input">
                    <el-select
                      v-model="physician"
                      placeholder="Select"
                      filterable
                      clearable
                      default-first-option
                      @change="handleCurrentChange(1)"
                      :loading="physiciansLoading"
                      remote
                      :remote-method="remoteMethodPhysician"
                    >
                      <el-option
                        v-for="physician in physiciansData.data"
                        :key="physician"
                        :label="physician.name"
                        :value="physician._id"
                      >
                      </el-option>
                    </el-select>
                    <span class="search-icon">
                      <img
                        src="@/assets/images/icons/search-icon-with-bg-red.svg"
                        alt="icon"
                        class="img-fluid"
                      />
                    </span>
                  </div>
                </li>

                <li v-else-if="searchBy == 'case_types'">
                  <div
                    class="form-element search-with-filter-input"
                    v-if="
                      getAuthenticatedUser.user_type == 'LAB_TECHNOLOGIST' &&
                      getAuthenticatedUser.allowed_case_types
                    "
                  >
                    <el-select
                      v-model="caseType"
                      placeholder="Select"
                      filterable
                      clearable
                      default-first-option
                      :reserve-keyword="false"
                      @change="handleCurrentChange(1)"
                    >
                      <el-option
                        v-for="caseType in getUserBasedCaseTypes(caseTypes)"
                        :key="caseType"
                        :label="caseType.label"
                        :value="caseType.value"
                      >
                      </el-option>
                    </el-select>
                    <span class="search-icon">
                      <img
                        src="@/assets/images/icons/search-icon-with-bg-red.svg"
                        alt="icon"
                        class="img-fluid"
                      />
                    </span>
                  </div>
                  <div class="form-element search-with-filter-input" v-else>
                    <el-select
                      v-model="caseType"
                      placeholder="Select"
                      filterable
                      clearable
                      default-first-option
                      :reserve-keyword="false"
                      @change="handleCurrentChange(1)"
                    >
                      <el-option
                        v-for="caseType in getLabBasedCaseTypes(caseTypes)"
                        :key="caseType"
                        :label="caseType.label"
                        :value="caseType.value"
                      >
                      </el-option>
                    </el-select>
                    <span class="search-icon">
                      <img
                        src="@/assets/images/icons/search-icon-with-bg-red.svg"
                        alt="icon"
                        class="img-fluid"
                      />
                    </span>
                  </div>
                </li>
                <li v-else-if="searchBy == 'hospital_marketers'">
                  <div class="form-element search-with-filter-input marketers">
                    <el-select
                      v-model="hospital_marketer"
                      placeholder="Select Sales Rep"
                      default-first-option
                      :reserve-keyword="false"
                      filterable
                      clearable
                      @change="handleCurrentChange(1)"
                      @focus="fetchAllUsers"
                      :loading="userLoading"
                      multiple
                      collapse-tags
                    >
                      <el-option
                        v-for="(user, index) in marketerData"
                        :key="index"
                        :label="user.first_name + ' ' + user.last_name"
                        :value="user._id"
                      >
                      </el-option>
                    </el-select>
                    <span class="search-icon">
                      <img
                        src="@/assets/images/icons/search-icon-with-bg-red.svg"
                        alt="icon"
                        class="img-fluid"
                      />
                    </span>
                  </div>
                </li>
                <li>
                  <el-button
                    class="btn button-with-icon"
                    @click="onReportsExport"
                  >
                    <span class="mr-8">
                      <img
                        src="@/assets/images/icons/export-icon-with-bg-blue.svg"
                        alt="icon"
                        class="inactive"
                      />
                      <img
                        src="@/assets/images/icons/export-icon-white.svg"
                        alt="icon"
                        class="active"
                      />
                    </span>
                    <span>Export</span>
                  </el-button>
                </li>
              </ul>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <div class="container-default" style="padding-top: 0">
      <div class="primary-content-view" v-loading="loading">
        <div
          class="listings listings__table listings__all-cases"
          id="all-cases-listings"
        >
          <el-table
            :data="getAllFiles.data"
            height="calc(100vh - 200px)"
            :default-sort="{
              prop: orderBy,
              order: orderType == 'asc' ? 'ascending' : 'descending',
            }"
            @sort-change="onChangeSort"
          >
            <el-table-column label="EMR #" min-width="150" fixed="left">
              <template #default="scope">
                <div class="emr-id" v-if="scope.row.patient_info">
                  <span @click="goToViewOrders(scope.row._id)" class="cursor">
                    {{
                      scope.row.patient_info.emr
                        ? scope.row.patient_info.emr
                        : "--"
                    }}
                  </span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="Case Type"
              min-width="125px"
              prop="case_types"
              sortable="custom"
              fixed="left"
            >
              <template #default="scope">
                <div
                  class="case-type"
                  v-if="scope.row.case_types && scope.row.case_types.length > 1"
                >
                  <el-dropdown>
                    <span class="case-data-type">
                      {{ getCaseTypes(scope.row.case_types)[0] }}

                      <img
                        src="@/assets/images/case-type-arrow.svg"
                        alt="icon"
                      />
                    </span>

                    <template #dropdown>
                      <el-dropdown-menu class="case-type-dropdown">
                        <el-dropdown-item
                          class="cases-view"
                          v-for="(caseType, index) of getCaseTypes(
                            scope.row.case_types
                          )"
                          :key="index"
                        >
                          {{ caseType }}
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </div>
                <div
                  v-else-if="
                    scope.row.case_types && scope.row.case_types.length == 1
                  "
                  class="case-type"
                >
                  <span class="case-data-type">
                    {{ getCaseTypes(scope.row.case_types)[0] }}
                  </span>
                </div>
                <div v-else></div>
              </template>
            </el-table-column>
            <el-table-column
              prop="patient_info.first_name"
              label="PATIENT NAME"
              min-width="150"
              sortable="custom"
              fixed="left"
            >
              <template #default="scope">
                <el-button
                  placement="top"
                  class="title-on-hover"
                  :title="getPatientNameByInfo(scope.row.patient_info)"
                  @click="
                    copyTitle(getPatientNameByInfo(scope.row.patient_info))
                  "
                >
                  <span class="text-center ordering_facility-value">{{
                    getPatientNameByInfo(scope.row.patient_info)
                  }}</span>
                </el-button>
              </template>
            </el-table-column>
            <el-table-column label="Patient DOB" min-width="130">
              <template #default="scope">
                {{ formatDate(scope.row.patient_info.date_of_birth) }}
              </template>
            </el-table-column>
            <el-table-column
              label="CREATED ON"
              min-width="130"
              prop="created_at"
              sortable="custom"
            >
              <template #default="scope">
                <div v-if="scope.row.created_at" class="d-flex">
                  <div class="date pr-10">
                    {{ momentWithTimezone(scope.row.created_at, "MM-DD-yyyy") }}
                  </div>
                </div>
              </template>
            </el-table-column>

            <el-table-column
              prop="hospital"
              label="ORDERING FACILITIES"
              min-width="200"
            >
              <template #default="scope">
                <el-button
                  placement="top"
                  class="title-on-hover"
                  :title="scope.row.hospital ? scope.row.hospital.name : '--'"
                >
                  <span class="ordering_facility-value">{{
                    scope.row.hospital ? scope.row.hospital.name : "--"
                  }}</span>
                </el-button>
              </template>
            </el-table-column>

            <el-table-column label="PHYSICIAN" min-width="150">
              <template #default="scope">
                <el-button
                  placement="top"
                  class="title-on-hover"
                  :title="
                    scope.row.ordering_physician
                      ? scope.row.ordering_physician.name.includes('Dr')
                        ? scope.row.ordering_physician.name
                        : 'Dr ' + scope.row.ordering_physician.name
                      : '--'
                  "
                >
                  <span class="ordering_physician-value">
                    {{
                      scope.row.ordering_physician
                        ? scope.row.ordering_physician.name.includes("Dr")
                          ? scope.row.ordering_physician.name
                          : "Dr " + scope.row.ordering_physician.name
                        : "--"
                    }}
                  </span>
                </el-button>
              </template>
            </el-table-column>

            <el-table-column
              label="Status"
              width="130"
              prop="status"
              sortable="custom"
            >
              <template #default="scope">
                <div class="column-status" v-if="scope.row.status">
                  <span
                    class="el-dropdown-link"
                    v-if="scope.row.status == 'COMPLETED'"
                  >
                    <el-tag :type="getStatusType(scope.row.status)"
                      >FINALIZED</el-tag
                    >
                  </span>
                  <span
                    class="el-dropdown-link"
                    v-else-if="scope.row.status == 'CANCELLED'"
                  >
                    <el-tag :type="getStatusType(scope.row.status)"
                      >REJECTED</el-tag
                    >
                  </span>
                  <span
                    class="el-dropdown-link"
                    v-else-if="scope.row.status == 'ACTIVE'"
                  >
                    <el-tag :type="getStatusType(scope.row.status)"
                      >OPEN</el-tag
                    >
                  </span>

                  <span class="el-dropdown-link" v-else>
                    <el-tag :type="getStatusType(scope.row.status)">{{
                      scope.row.status
                    }}</el-tag>
                  </span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="Phlebio Status" width="130">
              <template #default="scope">
                <div class="column-status" v-if="scope.row.phlebio_order_id">
                  <span class="el-dropdown-link">
                    {{
                      scope.row.phlebio_order_id
                        ? scope.row.phlebio_order_id.Status
                        : "--"
                    }}
                  </span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="Accession ID"
              width="285"
              prop="accession_id"
              sortable="custom"
            >
              <template #default="scope">
                <div
                  class="name-accession cursor"
                  v-if="
                    scope.row.accession_data && scope.row.accession_data.length
                  "
                >
                  <span
                    id="accession-id-blue"
                    class="order-accession"
                    v-for="(acccession, index) in scope.row.accession_data"
                    :key="index"
                    @click="goToCaseView(acccession.case_id)"
                  >
                    {{ acccession.accession_id }}
                  </span>
                </div>
                <div
                  class="name-accession cursor"
                  @click="goToCaseView(scope.row.case_id)"
                  v-else-if="scope.row.accession_id"
                >
                  <span id="accession-id-blue" class="order-accession">
                    {{ scope.row.accession_id }}
                  </span>
                </div>
              </template>
            </el-table-column>

            <el-table-column
              prop="action"
              label="ACTIONS"
              width="150"
              fixed="right"
            >
              <template #default="scope">
                <ul class="listings__action-buttons">
                  <li @click="goToViewOrders(scope.row._id)">
                    <el-button class="view-button" title="View EMR">
                      <img
                        class="action-active"
                        src="@/assets/images/icons/actions/view-icon.svg"
                        alt="icon"
                      />
                      <img
                        class="action-inactive"
                        src="@/assets/images/icons/actions/view-inactive.svg"
                        alt="icon"
                      />
                    </el-button>
                  </li>

                  <li
                    v-if="
                      scope.row.status != 'COMPLETED' &&
                      scope.row.status != 'CANCELLED' &&
                      scope.row.status != 'ARCHIVED' &&
                      hasPermission('emr_orders', 'reject')
                    "
                  >
                    <el-button
                      class="view-button"
                      @click="onReject(scope.row)"
                      title="Reject EMR"
                    >
                      <img
                        src="@/assets/images/icons/actions/reject-icon.svg"
                        alt="icon"
                        class="action-inactive"
                      />
                      <img
                        src="@/assets/images/icons/actions/reject-icon-active.svg"
                        class="action-active"
                        alt="icon"
                      />
                    </el-button>
                  </li>
                  <li v-if="hasPermission('orders', 'archive_orders')">
                    <el-button
                      class="reports-button validation-button"
                      @click="moveToValidations(scope.row._id)"
                      title="Move To Validation"
                    >
                      <img
                        class="action-active"
                        src="@/assets/images/icons/actions/validation-new-active.svg"
                        alt="icon"
                      />
                      <img
                        class="action-inactive"
                        src="@/assets/images/icons/actions/validation-new-inactive.svg"
                        alt="icon"
                      />
                    </el-button>
                  </li>
                  <li
                    v-if="
                      scope.row.status == 'ACTIVE' &&
                      !scope.row.send_to_phlebio &&
                      hasPermission('emr_orders', 'edit')
                    "
                  >
                    <el-button
                      class="view-button"
                      title="SEND TO PHLEBIO"
                      @click="sendOrderToPhlebio(scope.row)"
                    >
                      <img
                        class="action-active"
                        src="@/assets/images/icons/actions/phlebio_send.svg"
                        alt="icon"
                      />
                      <img
                        class="action-inactive"
                        src="@/assets/images/icons/actions/phlebio_send_inactive.svg"
                        alt="icon"
                      />
                    </el-button>
                  </li>
                  <li
                    v-if="
                      scope.row.send_to_phlebio &&
                      hasPermission('emr_orders', 'edit')
                    "
                  >
                    <el-button
                      class="view-button"
                      title="VIEW PHLEBIO"
                      @click="viewPhlebioOrder(scope.row.phlebio_order_id._id)"
                    >
                      <img
                        class="action-active"
                        src="@/assets/images/icons/actions/phlebio-view-active.svg"
                        alt="icon"
                      />
                      <img
                        class="action-inactive"
                        src="@/assets/images/icons/actions/phlebio-view-inactive.svg"
                        alt="icon"
                      />
                    </el-button>
                  </li>
                </ul>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="listings listings__pagination">
          <el-pagination
            v-model:currentPage="page"
            v-model:page-size="pageSize"
            :page-sizes="[25, 50, 75, 100]"
            layout=" prev, pager, next, jumper, sizes, total"
            :total="getAllFiles.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
      <!-- <div class="default-modal">
        <div class="archive-popup">
          <el-dialog top="25vh" width="30%" v-model="rejectPopup">
            <div class="dialog-header">
              <span class="header-part">
                <img src="@/assets/images/reason.svg" alt="icon" />
                Reason
              </span>
              <el-button
                size="medium"
                class="el-white"
                type="info"
                plain
                @click="rejectPopup = false"
              >
                <img src="@/assets/images/close-icon.svg" alt="icon"
              /></el-button>
            </div>

            <el-row :gutter="20" class="popup-text-area pb-0">
              <el-col :span="24">
                <h4>Notes</h4>
                <el-input
                  type="textarea"
                  rows="2"
                  v-model="emrNotes"
                  placeholder="Notes"
                ></el-input>
                <p><b>Note:</b> Minimum 3 characters is required</p>
              </el-col>
              <el-col :span="24">
                <div class="dialog-footer text-center">
                  <el-button
                    class="blue-cancel-button"
                    @click="rejectPopup = false"
                  >
                    CANCEL</el-button
                  >
                  <el-button
                    class="blue-save-button"
                    :disabled="!haveNotes"
                    :title="haveNotes ? 'Reject EMR' : 'Notes is Required'"
                    @click="rejectEmr"
                    :loading="loading"
                  >
                    REJECT EMR</el-button
                  >
                </div>
              </el-col>
            </el-row>
          </el-dialog>
        </div>
      </div> -->
    </div>
    <EmrOrdersExport
      v-if="exportReportsDialogVisisble"
      v-on:close="exportReportsDialogVisisble = false"
      :selected_emr_client="emr_client"
      :selected_hospital="hospital"
      :selected_physician="physician"
      :selected_string="search_string"
      :selected_case_types="caseType"
      :selected_dob="date_of_birth"
      :selected_accession="accession_id"
      :selected_status="selectedStatuses"
      :selected_marketer="hospital_marketer"
    ></EmrOrdersExport>
    <ResonForValidation
      v-if="takeValidationReasonDialog"
      v-on:close="takeValidationReasonDialog = false"
      v-on:reasonSubmit="moveToValidation"
      title="Reason for Validation"
    ></ResonForValidation>
    <div class="default-modal" v-if="phlebioAddressPopup">
      <el-dialog
        v-model="phlebioAddressPopup"
        :before-close="handleClose"
        width="1000px"
        v-loading="loading"
      >
        <div class="export-dialog-header">
          <div class="export-header-part pl-1r">
            <h3>SEND TO PHLEBIO</h3>
          </div>

          <el-button
            size="medium"
            class="el-white"
            type="info"
            plain
            @click="handleClose"
          >
            <img src="@/assets/images/icons/cases/close-btn.svg" alt="icon"
          /></el-button>
        </div>
        <div class="popup-date pb-0">
          <div class="info-view">
            <el-row :gutter="20">
              <el-col :xl="6" :lg="6" :md="8" :sm="12" :xs="12">
                <div class="input-value view-cases">
                  <h4 class="name">EMR</h4>
                  <p
                    class="value"
                    v-if="
                      phlebioOrderData &&
                      phlebioOrderData.patient_info &&
                      phlebioOrderData.patient_info.emr
                    "
                  >
                    {{ phlebioOrderData.patient_info.emr }}
                  </p>
                  <p class="value" v-else>--</p>
                </div>
              </el-col>
              <el-col :xl="6" :lg="6" :md="8" :sm="12" :xs="12">
                <div class="input-value view-cases">
                  <label class="name">CASE TYPE</label>
                  <div
                    class="value"
                    v-if="phlebioOrderData && phlebioOrderData.case_types"
                  >
                    <ul>
                      <li
                        v-for="(caseType, index) of getCaseTypes(
                          phlebioOrderData.case_types
                        )"
                        :key="index"
                      >
                        {{ caseType }}
                      </li>
                    </ul>
                  </div>
                  <div class="value" v-else>--</div>
                </div>
              </el-col>
              <el-col :xl="6" :lg="6" :md="8" :sm="12" :xs="12">
                <div class="input-value view-cases">
                  <h4 class="name">PATIENT NAME</h4>
                  <p
                    class="value"
                    v-if="phlebioOrderData && phlebioOrderData.patient_info"
                  >
                    {{ getPatientNameByInfo(phlebioOrderData.patient_info) }}
                  </p>
                  <p class="value" v-else>--</p>
                </div>
              </el-col>
              <el-col :xl="6" :lg="6" :md="8" :sm="12" :xs="12">
                <div class="input-value view-cases">
                  <h4 class="name">PATIENT DOB</h4>
                  <p
                    class="value"
                    v-if="
                      phlebioOrderData &&
                      phlebioOrderData.patient_info &&
                      phlebioOrderData.patient_info.date_of_birth
                    "
                  >
                    {{
                      formatDate(phlebioOrderData.patient_info.date_of_birth)
                    }}
                  </p>
                  <p class="value" v-else>--</p>
                </div>
              </el-col>
              <el-col :xl="6" :lg="6" :md="8" :sm="12" :xs="12">
                <div class="input-value view-cases">
                  <h4 class="name">CREATED ON</h4>
                  <p
                    class="value"
                    v-if="phlebioOrderData && phlebioOrderData.created_at"
                  >
                    {{
                      momentWithTimezone(
                        phlebioOrderData.created_at,
                        "MM-DD-yyyy"
                      )
                    }}
                  </p>
                  <p class="value" v-else>--</p>
                </div>
              </el-col>
              <el-col :xl="6" :lg="6" :md="8" :sm="12" :xs="12">
                <div class="input-value view-cases">
                  <h4 class="name">ORDERING FACILITIES</h4>
                  <p
                    class="value"
                    v-if="phlebioOrderData && phlebioOrderData.hospital"
                  >
                    {{ phlebioOrderData.hospital.name }}
                  </p>
                  <p class="value" v-else>--</p>
                </div>
              </el-col>
              <el-col :xl="6" :lg="6" :md="8" :sm="12" :xs="12">
                <div class="input-value view-cases">
                  <h4 class="name">PHYSICIAN</h4>
                  <p
                    class="value"
                    v-if="
                      phlebioOrderData &&
                      phlebioOrderData.ordering_physician &&
                      phlebioOrderData.ordering_physician.name
                    "
                  >
                    {{ phlebioOrderData.ordering_physician.name }}
                  </p>
                  <p class="value" v-else>--</p>
                </div>
              </el-col>
              <el-col :xl="6" :lg="6" :md="8" :sm="12" :xs="12">
                <div class="input-value view-cases">
                  <h4 class="name">STATUS</h4>
                  <p
                    class="value"
                    v-if="phlebioOrderData && phlebioOrderData.status"
                  >
                    {{ phlebioOrderData.status }}
                  </p>
                  <p class="value" v-else>--</p>
                </div>
              </el-col>
              <el-col :xl="12" :lg="12" :md="12" :sm="12" :xs="12">
                <div class="input-value view-cases">
                  <h4 class="name">EMR NOTES</h4>
                  <p
                    class="value"
                    v-if="
                      phlebioOrderData &&
                      phlebioOrderData.emr_notes &&
                      phlebioOrderData.emr_notes.length > 0
                    "
                  >
                    {{ phlebioOrderData.emr_notes[0] }}
                  </p>
                  <p class="value" v-else>--</p>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="popup-date pb-0 pt-0">
          <div class="phlebio-radio">
            <el-radio-group class="re-order-radio-list" v-model="addressData">
              <el-radio label="patient">PATIENT</el-radio>
              <el-radio label="hospital">FACILITY</el-radio>
              <el-radio label="others">OTHER</el-radio>
            </el-radio-group>
          </div>
          <div v-if="addressData && addressData == 'others'">
            <el-form class="phlebio-inputs">
              <el-form-item>
                <h4 class="name">ADDRESS LINE 1</h4>
                <el-input
                  v-model="address_value.address_line_1"
                  placeholder="Enter Address"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <h4 class="name">ZIP</h4>
                <el-input
                  v-model="address_value.zip"
                  placeholder="Enter Zip"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <h4 class="name">STATE</h4>
                <el-input
                  v-model="address_value.state"
                  placeholder="Enter State"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <h4 class="name">CITY</h4>
                <el-input
                  v-model="address_value.city"
                  placeholder="Enter City"
                ></el-input>
              </el-form-item>
            </el-form>
          </div>

          <div v-if="addressData && addressData == 'patient'">
            <el-form class="phlebio-inputs">
              <el-form-item>
                <h4 class="name">ADDRESS LINE 1</h4>
                <el-input
                  v-model="patient_address_value.address_line_1"
                  placeholder="Enter Address"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <h4 class="name">ZIP</h4>
                <el-input
                  v-model="patient_address_value.zip"
                  placeholder="Enter Zip"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <h4 class="name">STATE</h4>
                <el-input
                  v-model="patient_address_value.state"
                  placeholder="Enter State"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <h4 class="name">CITY</h4>
                <el-input
                  v-model="patient_address_value.city"
                  placeholder="Enter City"
                ></el-input>
              </el-form-item>
            </el-form>
          </div>

          <div v-if="addressData && addressData == 'hospital'">
            <el-form class="phlebio-inputs">
              <el-form-item>
                <h4 class="name">ADDRESS LINE 1</h4>
                <el-input
                  v-model="facility_address_value.address_line_1"
                  placeholder="Enter Address"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <h4 class="name">ZIP</h4>
                <el-input
                  v-model="facility_address_value.zip"
                  placeholder="Enter Zip"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <h4 class="name">STATE</h4>
                <el-input
                  v-model="facility_address_value.state"
                  placeholder="Enter State"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <h4 class="name">CITY</h4>
                <el-input
                  v-model="facility_address_value.city"
                  placeholder="Enter City"
                ></el-input>
              </el-form-item>
            </el-form>
          </div>
          <div class="dialog-footer">
            <el-button
              @click="sendOrderPhlebio()"
              class="blue-save-button"
              :disabled="shouldDisableSendOrder"
              :loading="phlenioLoading"
              >SEND ORDER TO PHLEBIO</el-button
            >
          </div>
        </div>
      </el-dialog>
    </div>
    <ReasonForStatusChange
      v-if="rejectPopup"
      v-on:close="rejectPopup = false"
      v-on:reasonSubmit="rejectEmr"
      :width="40"
      :title="takeStatusChangeReasonDialogTitle"
    ></ReasonForStatusChange>
  </section>
</template>

<script>
import { defineAsyncComponent } from "vue";

import { mapGetters } from "vuex";
import moment from "moment";
import caseConstants from "@/config/constants/caseConstants";
import { emrSearchOptions } from "@/config/constants/searchConstants";
const EmrOrdersExport = defineAsyncComponent(() =>
  import("@/components/EMROrders/EmrOrdersExport")
);
import FilterHelper from "@/mixins/FilterHelper";
import caseDataHelper from "@/mixins/caseDataHelper";
import {
  successNotification,
  errorNotification,
} from "@/helpers/notifications";
import UserPermissionsHelper from "@/mixins/UserPermissionsHelper";
const ResonForValidation = defineAsyncComponent(() =>
  import("@/components/misc/ResonForChange")
);
const ReasonForStatusChange = defineAsyncComponent(() =>
  import("@/components/misc/OrderReasonForChange")
);
export default {
  name: "allEmrOrders",
  components: {
    EmrOrdersExport,
    ResonForValidation,
    ReasonForStatusChange,
  },
  props: {
    selectedStatuses: {
      default: [],
    },
  },
  data() {
    return {
      disabledDate: (time) => {
        return time.getTime() > Date.now();
      },
      scrollPosition: null,
      exportReportsDialogVisisble: false,
      rejectPopup: false,
      page: 1,
      pageSize: 25,
      caseType: "",
      caseTypeMap: caseConstants.CASE_TYPE_MAP,
      caseTypes: caseConstants.CASE_TYPES_DATA,
      searchOptions: emrSearchOptions,
      loading: false,
      search_string: "",
      orderBy: "",
      orderType: "",
      date_of_birth: "",
      hospital: "",
      physician: "",
      accession_id: "",
      searchBy: "",
      emr_client: "",
      emrClients: [],
      emrNotes: "",
      hospitalsData: {},
      physiciansData: {},
      hospitalsLoading: false,
      physiciansLoading: false,
      emrClientsLoading: false,
      hospital_marketer: [],
      marketerData: [],
      userLoading: false,
      accessionCaseType: [],
      takeValidationReasonDialog: false,
      requestValidationEMROrder: "",
      phlebioAddressPopup: false,
      phlebioOrderData: {},
      addressData: "others",
      address_value: {
        address_line_1: "",
        city: "",
        state: "",
        zip: "",
      },
      patient_address_value: {
        address_line_1: "",
        city: "",
        state: "",
        zip: "",
      },
      facility_address_value: {
        address_line_1: "",
        city: "",
        state: "",
        zip: "",
      },
      phlenioLoading: false,
      takeStatusChangeReasonDialog: false,
      takeStatusChangeReasonDialogTitle: "",
    };
  },
  created() {
    if (this.isFacilityUser || this.isLabAssistantUser) {
      let markerFilterIndex = this.searchOptions.findIndex(
        (option) => option.value == "hospital_marketers"
      );
      if (markerFilterIndex > -1) {
        this.searchOptions[markerFilterIndex].active = false;
      }
    }
    this.searchOptions = this.searchOptions.filter((option) => option.active);
  },
  computed: {
    Reason() {
      if (this.emrNotes && this.emrNotes.length >= 3) {
        return true;
      }

      return false;
    },
    haveNotes() {
      return this.emrNotes && this.emrNotes.trim().length >= 3;
    },
    getStatusType() {
      return (statusVal) => {
        const status = statusVal.trim();
        if (status == "ACTIVE") {
          return "default";
        } else if (status == "COMPLETED") {
          return "success";
        } else if (status == "CANCELLED") {
          return "danger";
        } else if (status == "ARCHIVED") {
          return "danger";
        } else {
          return "";
        }
      };
    },
    ...mapGetters("emrOrders", [
      "getAllFiles",
      "getAllEMRClients",
      "getSendReportStatus",
      "getUpdateValidationStatus",
      "getBulkValidations",
    ]),
    ...mapGetters("physicians", ["getAllPhysicians"]),
    ...mapGetters("hospitals", ["getAllHospitals"]),
    ...mapGetters("auth", ["getAuthenticatedUser"]),
    ...mapGetters("userAccounts", ["getAllUsers"]),
    ...mapGetters("phlebio", ["getSendOrdersToPhlebioStatus"]),
    getCaseTypes() {
      return (caseTypes) => {
        return (caseTypes || []).map((e) => this.caseTypeMap[e]);
      };
    },
    shouldDisableSendOrder() {
      if (this.addressData === "others") {
        const { address_line_1, zip, state, city } = this.address_value || {};
        return !(address_line_1 && zip && state && city); // Disable button if any field is empty
      } else if (this.addressData === "hospital") {
        const { address_line_1, zip, state, city } =
          this.facility_address_value || {};
        return !(address_line_1 && zip && state && city);
      } else if (this.addressData === "patient") {
        const { address_line_1, zip, state, city } =
          this.patient_address_value || {};
        return !(address_line_1 && zip && state && city);
      }
      return false; // Enable button otherwise
    },
    ...mapGetters("hospitals", ["getSingleHospital"]),
  },
  mixins: [FilterHelper, caseDataHelper, UserPermissionsHelper],
  async mounted() {
    this.setQueryParamsData();
    await this.fetchEmrOrders();
  },
  methods: {
    initializeSearchOptions() {
      this.searchOptions = [...this.searchOptions];
      this.filterSearchOptions();
    },
    filterSearchOptions() {
      if (this.isPhysicianUser) {
        // Remove options for facility users
        const optionsToRemove = ["emr_client"];
        this.searchOptions = this.searchOptions.filter(
          (option) => !optionsToRemove.includes(option.value)
        );
      }
      if (this.isHospitalUser) {
        const optionsToRemove = ["emr_client"];
        this.searchOptions = this.searchOptions.filter(
          (option) => !optionsToRemove.includes(option.value)
        );
      }
    },
    viewPhlebioOrder(phleboId) {
      this.$router.push({
        name: "PhlebioOrderDetails",
        params: {
          phlebio_id: phleboId,
        },
        query: { ...this.$route.query, redirectRoute: this.$route.name },
      });
    },
    async sendOrderToPhlebio(emrData) {
      this.address_value = {};
      this.phlebioOrderData = emrData;
      if (emrData && emrData.patient_info) {
        this.patient_address_value.address_line_1 =
          emrData.patient_info.address_line_1 || "";
        this.patient_address_value.zip = emrData.patient_info.zip || "";
        this.patient_address_value.state = emrData.patient_info.state || "";
        this.patient_address_value.city = emrData.patient_info.city || "";
      }
      if (emrData && emrData.hospital) {
        this.loading = true;
        let hospitalId = emrData.hospital._id;
        await this.$store.dispatch("hospitals/fetchSingleHospital", hospitalId);
        this.loading = false;
        if (this.getSingleHospital) {
          this.facility_address_value.address_line_1 =
            this.getSingleHospital.address_line_1 || "";
          this.facility_address_value.zip = this.getSingleHospital.zip || "";
          this.facility_address_value.state =
            this.getSingleHospital.state || "";
          this.facility_address_value.city = this.getSingleHospital.city || "";
        }
      }
      if (
        emrData &&
        Array.isArray(emrData.emr_notes) &&
        emrData.emr_notes.length > 0
      ) {
        let emrNotes = emrData.emr_notes[0];
        let dataSplit = emrNotes.split(",");
        // Assuming the format is always "address_line_1, city, state zip"
        this.address_value.address_line_1 =
          (dataSplit[0] && dataSplit[0].trim()) || "";
        this.address_value.city = (dataSplit[1] && dataSplit[1].trim()) || "";
        // Split the state and zip part
        let stateZip = (dataSplit[2] && dataSplit[2].trim().split(" ")) || [];
        this.address_value.state = stateZip[0] || "";
        this.address_value.zip = stateZip[1] || "";
      }
      this.phlebioAddressPopup = true;
    },
    async sendOrderPhlebio() {
      try {
        this.phlenioLoading = true;
        let params = {
          emr_id: this.phlebioOrderData._id,
          to_address: this.addressData,
        };
        if (this.addressData && this.addressData == "others") {
          params.address_value = this.address_value;
        }
        if (this.addressData && this.addressData == "hospital") {
          params.address_value = this.facility_address_value;
        }
        if (this.addressData && this.addressData == "patient") {
          params.address_value = this.patient_address_value;
        }
        await this.$store.dispatch("phlebio/sendOrdersToPhlebio", params);
        if (this.getSendOrdersToPhlebioStatus) {
          successNotification("EMR Order send to phlebio successfully");
          this.address_value = {};
          this.patient_address_value = {};
          this.facility_address_value = {};
          this.phlebioAddressPopup = false;
          await this.fetchEmrOrders();
        } else {
          errorNotification("Error while sending EMR order to phlebio");
        }
        this.phlenioLoading = false;
      } catch (err) {
        this.phlenioLoading = false;
        console.log(err);
      } finally {
        this.phlenioLoading = false;
      }
    },
    async remoteMethodPhysician(query) {
      if (query !== "") {
        localStorage.setItem("searchQueryPhysician", query);
        await this.fetchAllPhysicians(query);
      }
    },
    async moveToValidations(validation) {
      this.requestValidationEMROrder = validation;
      this.takeValidationReasonDialog = true;
    },
    async moveToValidation(reason) {
      try {
        this.loading = true;
        let params = {
          id: this.requestValidationEMROrder,
          status: true,
          validation_reason: reason,
        };
        await this.$store.dispatch(
          "emrOrders/changeCaseValidationStatus",
          params
        );
        if (this.getUpdateValidationStatus) {
          successNotification("EMR order moved to validation EMR orders");
          await this.fetchEmrOrders();
        } else {
          errorNotification("Error while moving to validation EMR orders");
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    async fetchAllUsers() {
      this.userLoading = true;

      await this.$store.dispatch("userAccounts/fetchAllUserAccounts", {
        get_all: true,
        user_types: ["MARKETER", "HOSPITAL_MARKETING_MANAGER"],
        order_by: "first_name",
        order_type: "asc",
      });
      if (this.getAllUsers && this.getAllUsers.data) {
        this.marketerData = this.getAllUsers.data;
      }
      this.userLoading = false;
    },
    async onReportsExport() {
      this.loading = true;

      this.exportReportsDialogVisisble = true;
      this.loading = false;
    },
    onReject(emrOrder) {
      this.selectedEmr = emrOrder;
      this.rejectPopup = true;
      this.takeStatusChangeReasonDialogTitle = "REJECTED";
    },
    clearSearchBy() {
      if (this.$route.query.selectedEmrSearchBy == "send_to_phlebio") {
        this.fetchEmrOrders(1);
      }
    },
    clearInputSearch() {
      if (
        this.hospital ||
        this.physician ||
        this.date_of_birth ||
        this.caseType ||
        this.emr_client ||
        this.hospital_marketer.length
      ) {
        this.hospital = "";
        this.physician = "";
        this.date_of_birth = "";
        this.caseType = "";
        this.emr_client = "";
        this.hospital_marketer = [];
        this.page = 1;
        this.fetchEmrOrders(1);
      }
      if (this.searchBy == "send_to_phlebio") {
        this.fetchEmrOrders(1);
      }
    },
    async fetchAllEMRClients() {
      try {
        this.emrClientsLoading = true;

        await this.$store.dispatch("emrOrders/fetchAllEMRClients", {
          get_all: true,
        });
        this.filterEmrClient();
        this.emrClientsLoading = false;
      } catch (err) {
        this.loading = false;
      }
    },
    filterEmrClient(filterValue) {
      if (filterValue && filterValue.length) {
        this.emrClients = [...this.getAllEMRClients.data].filter(
          (option) =>
            option.name.toLowerCase().indexOf(filterValue.toLowerCase()) == 0
        );
      } else {
        this.emrClients = this.getAllEMRClients.data;
      }
    },
    async fetchAllPhysicians(physician) {
      try {
        this.physiciansLoading = true;
        let params = {
          limit: 25,
        };
        if (physician) {
          params.search_string = physician;
        }
        await this.$store.dispatch("physicians/fetchAllPhysicians", params);
        this.physiciansData = JSON.parse(JSON.stringify(this.getAllPhysicians));
        this.physiciansLoading = false;
      } catch (error) {
        console.log(error);
      }
    },
    async fetchAllHospitals() {
      try {
        this.hospitalsLoading = true;
        await this.$store.dispatch("hospitals/fetchAllHospitals", {
          get_all: true,
        });
        this.hospitalsData = JSON.parse(JSON.stringify(this.getAllHospitals));
        this.hospitalsLoading = false;
      } catch (error) {
        console.log(error);
      }
    },

    onChangeSort({ order, prop }) {
      if (order && prop) {
        this.orderBy = prop;
        this.orderType = order == "ascending" ? "asc" : "desc";
      } else {
        this.orderBy = "";
        this.orderType = "";
      }
      this.fetchEmrOrders();
    },
    setQueryParamsData() {
      this.page = this.$route.query.selectedEmrPage
        ? parseInt(this.$route.query.selectedEmrPage)
        : 1;

      if (this.$route.query.search_string) {
        this.search_string = this.$route.query.search_string;
      }
      if (this.$route.query.selectedEmr) {
        this.fetchAllEMRClients();
        this.emr_client = this.$route.query.selectedEmr;
      }
      if (this.$route.query.selectedMarketer) {
        this.fetchAllUsers();
        this.hospital_marketer = this.$route.query.selectedMarketer;
      }
      if (this.$route.query.selectedEmrPhysician) {
        const savedQuery = localStorage.getItem("searchQueryPhysician");
        if (savedQuery && this.$route.query.search_string !== savedQuery) {
          this.fetchAllPhysicians(savedQuery);
        }
        this.physician = this.$route.query.selectedEmrPhysician;
      }
      if (this.$route.query.selectedEmrHospital) {
        this.fetchAllHospitals();
        this.hospital = this.$route.query.selectedEmrHospital;
      }
      this.search_string = this.$route.query.selectedEmrString;

      this.pageSize = this.$route.query.selectedEmrPageSize
        ? parseInt(this.$route.query.selectedEmrPageSize)
        : 25;

      if (this.$route.query.selectedEmrCaseType) {
        this.caseType = this.$route.query.selectedEmrCaseType;
      }
      if (this.$route.query.selectedEmrSearchBy) {
        this.searchBy = this.$route.query.selectedEmrSearchBy;
      }
      if (this.$route.query.selectedEmrDateOfBirth) {
        this.date_of_birth = this.$route.query.selectedEmrDateOfBirth;
      }

      if (this.$route.query.selectedEmrOrderBy) {
        this.orderBy = this.$route.query.selectedEmrOrderBy;
      }
      if (this.$route.query.selectedEmrOrderType) {
        this.orderType = this.$route.query.selectedEmrOrderType;
      }
    },
    prepareParams() {
      const params = {
        page: this.page,
        limit: this.pageSize,
        is_validation_purpose: false,
      };
      if (this.search_string) {
        params.search_string = this.search_string;
      }
      if (this.date_of_birth) {
        const date_of_birth = moment(this.date_of_birth).format("YYYY-MM-DD");

        params.date_of_birth = date_of_birth;
      }
      if (this.hospital) {
        params.hospital = this.hospital;
      }
      if (this.caseType) {
        params.case_type = this.caseType;
      }
      if (this.physician) {
        params.ordering_physician = this.physician;
      }
      if (this.emr_client) {
        params.emr_client = this.emr_client;
      }
      if (this.hospital_marketer.length) {
        params.hospital_marketers = this.hospital_marketer;
      }
      if (this.orderBy && this.orderType) {
        params.order_by = this.orderBy;
        params.order_type = this.orderType;
      }
      if (this.searchBy == "send_to_phlebio") {
        params.send_to_phlebio = true;
      }
      params.status = this.selectedStatuses;
      return params;
    },
    getAutoCompleteOrders() {
      setTimeout(() => {
        this.page = 1;
        this.fetchEmrOrders();
      }, 600);
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.page = 1;

      this.fetchEmrOrders();
    },
    handleCurrentChange(page) {
      this.page = page;

      this.fetchEmrOrders();
    },
    async fetchEmrOrders() {
      try {
        this.loading = true;
        let params = this.prepareParams();
        await this.$store.dispatch("emrOrders/fetchAllFiles", params);
        this.updateQueryParams(params);
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    updateQueryParams(params) {
      let selectedParams = {
        selectedEmrPage: params.page,
        selectedEmrCaseType: params.case_type,
        selectedEmrHospital: params.hospital,
        selectedEmrDateOfBirth: params.date_of_birth,
        selectedEmrPhysician: params.ordering_physician,
        selectedEmrString: params.search_string,
        selectedEmrPageSize: params.limit,
        selectedStatus: params.status,
        selectedEmrOrderBy: params.order_by,
        selectedEmrOrderType: params.order_type,
        selectedEmrAccessionId: params.accession_id,
        selectedEmrSearchBy: this.searchBy || undefined,
        selectedEmr: params.emr_client,
        selectedMarketer: params.hospital_marketers,
      };
      this.updateQueryParam(this.$route.name, selectedParams);
    },
    goToViewOrders(orderId) {
      this.$router.push({
        name: "View-Case-Information",
        params: { order_id: orderId },
        query: { ...this.$route.query, redirectRoute: this.$route.name },
      });
    },
    goToCaseView(caseId) {
      this.$router.push({
        name: "CaseView",
        params: { case_id: caseId },
        query: { ...this.$route.query, redirectRoute: this.$route.name },
      });
    },
    async rejectEmr(data) {
      try {
        this.loading = true;
        let params = {
          emr_result_status: "CANCELLED",
          order_id: this.selectedEmr._id,
          emr_order_id: this.selectedEmr._id,
        };
        if (data.notes != "") {
          params.notes = data.notes;
        }
        if (data.reasons && data.reasons.length > 0) {
          params.reasons = data.reasons;
        }
        await this.$store.dispatch("emrOrders/sendReportToEmr", params);
        if (this.getSendReportStatus) {
          this.rejectPopup = false;

          successNotification("EMR rejected successfully");
          this.emrNotes = "";
          await this.fetchEmrOrders(this.page);
        } else {
          errorNotification("Error in rejecting EMR");
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
        this.loading = false;
      }
    },
    handleClose() {
      this.phlebioAddressPopup = false;
    },
  },
};
</script>

<style lang="scss">
.image-fluid {
  width: 100%;
}
#all-emr-orders-view {
  .stickyNavbar {
    padding-bottom: 5px !important;
    .container-default {
      padding-top: 0;
    }
  }
}
.validation-button {
  display: block;
  margin-bottom: -5px;
  img {
    width: 25px;
  }
}
.phlebio-inputs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  row-gap: 10px;
  padding-top: 0.5rem;
  .el-input__inner {
    margin-top: 5px;
    background: #ffffff;
    border: 0.719521px solid #56ccf2;
    box-sizing: border-box;
    border-radius: 2px;
    height: 35px;
  }
  .name {
    height: 30px;
    color: #000000;
  }
}
.phlebio-radio {
  .el-radio-group {
    display: flex;
    .el-radio__input .el-radio__inner {
      border-color: transparent;
      box-shadow: 0 0 0 1px #ced1d6;
    }
    .el-radio__input.is-checked .el-radio__inner {
      background-color: #0a5da6;
      border-color: #ffffff;
    }
    .el-radio__input.is-checked + .el-radio__label {
      color: #0a5da6;
    }
  }
}
</style>
